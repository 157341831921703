import React from "react"
import Seo from "../app/services/seo"
import Layout from "../app/layouts/default"


import Breadcrumbs from "../app/components/breadcrumbs";
import data from '../data/about-us/index.json';
import Hero from "../app/components/hero";
import * as Megacontent from "../app/helpers/megacontent";

const breadcrumbs = [
    {
        link: '/',
        label: 'Home'
    },
    {
        label: 'Chi Siamo'
    }

];
const { hero, dynamic, gallery, triplet } = data;

const AboutUsPage = (props) => {
    return (
        <Layout noMarginFooter={true}>
            <Seo title="Cookie Policy" keywords={[`gatsby`, `application`, `react`]} />
            <div className="container">
                <Breadcrumbs homeLabel={'Home'} items={breadcrumbs} />
            </div>
            <Megacontent.Editorial
                textWall={true}
            >
                <h2>Cookie Policy</h2>
                <h2>Cosa sono i cookie</h2>
                <p>I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, dove vengono memorizzati per essere ritrasmessi agli stessi siti in occasione di visite successive. I cookie sono utilizzati per diverse finalità, hanno caratteristiche diverse, e possono essere utilizzati sia dal titolare del sito che si sta visitando, sia da terze parti. &nbsp;Di seguito troverai tutte le informazioni sui cookie installati attraverso questo sito, e le indicazioni necessarie su come gestire le tue preferenze riguardo ad essi. &nbsp;</p>
                <p>Per maggiori informazioni sui cookie e sulle loro funzioni generali, visita un sito Web informativo come <a href="http://www.allaboutcookies.org/">allaboutcookies.org</a>.</p>
                <h2>Cookie utilizzati da questo sito</h2>
                <p>L’utilizzo di cookie da parte del titolare di questo sito, Candy Hoover Group S.r.l. con unico socio, società soggetta ad attività di direzione e coordinamento di Candy S.p.A. Sede legale: Via Comolli, 16 - 20861 Brugherio (MB), sede amministrativa: Via Privata Eden Fumagalli - 20861 Brugherio (MB), si inquadra nella Privacy Policy dello stesso; per tutte le informazioni richieste dall’art. 13 Codice della Privacy <a href="/privacy-policy">clicca qui</a>.</p>
                <h2>Cookie tecnici che non rischiedono il consenso</h2>
                <p>Cookie relativi ad attività strettamente necessarie al funzionamento del sito e all’erogazione del servizio:</p>
                <ul>
                    <li>Cookie_Support, tecnico, expires never;</li>
                    <li>Jsessionid, di sessione;</li>
                    <li>Lfr_session_statexyz (dove xyz è un numero), di sessione ;</li>
                    <li>cookie-banner, tecnico;</li>
                </ul>
                <p>Cookie relativi ad attività di salvataggio delle preferenze e ottimizzazione;</p>
                <ul>
                    <li>Guest_language_id, tecnico, expires never;</li>
                </ul>
                <h2>Cookie per cui è richiesto il consenso</h2>
                <p>Tutti i cookie diversi da quelli tecnici sopra indicati vengono installati o attivati solo a seguito del <strong>consenso espresso </strong>dall’utente la prima volta che visita il sito. Il consenso può essere espresso in maniera generale, interagendo con il banner di informativa breve presente sulla pagina di approdo del sito, secondo le modalità indicate in tale banner (cliccando sul tasto OK o sul tasto X; oppure proseguendo la navigazione, anche con lo scroll o attraverso un link); oppure può essere fornito o negato in maniera selettiva, secondo le modalità di seguito indicate. Di questo consenso viene tenuta traccia in occasione delle visite successive. Tuttavia, <strong>l’utente ha sempre la possibilità di revocare in tutto o in parte il consenso già espresso.</strong></p>
                <h2>Cookie di statistica:</h2>
                <p>Vengono utilizzati da terze parti, anche in forma disaggregata, per la gestione di statistiche</p>
                <p>Troverai di seguito il nome dei terzi che li gestiscono, e per ciascuno di essi il link alla pagina nella quale potrai ricevere le informazioni sul trattamento ed esprimere il tuo consenso.</p>
                <p><strong>Google Analytics (Google Inc.)</strong></p>
                <p>Google Analytics è un servizio di analisi web fornito da Google Inc. ("Google"). Google utilizza i dati personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.<br />
                    Google potrebbe utilizzare i dati personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
                <p>Dati personali raccolti: cookie e dati di utilizzo.&nbsp;</p>
                <p>Luogo del trattamento: USA -&nbsp;<a href="https://support.google.com/analytics/answer/6004245?hl=it">Privacy Policy</a>&nbsp;-&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=it">Disattiva</a></p>
                <h2>Visualizzazione di contenuti da piattaforme esterne</h2>
                <p>Questi servizi permettono di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi.<br />
                    Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.</p>
                <p><strong>Widget Video YouTube (Google Inc.)</strong></p>
                <p>YouTube è un servizio di visualizzazione di contenuti video gestito da Google Inc. che permette a questa Applicazione di integrare tali contenuti all’interno delle proprie pagine.</p>
                <p>Dati personali raccolti: Cookie e Dati di utilizzo.</p>
                <p>Luogo del trattamento: USA -&nbsp;<a href="http://www.google.it/intl/it/policies/privacy/">Privacy Policy</a></p>
                <h2>Cookie gestiti da terze parti</h2>
                <p>Attraverso questo sito vengono installati anche cookie gestiti da terze parti. Troverai di seguito alcune indicazioni, e un link alla privacy policy e al modulo di consenso di ciascuna di esse. Per tutti potrai esprimere il tuo consenso anche proseguendo la navigazione o chiudendo questa finestra.&nbsp;</p>
                <h2>Cookie di profilazione pubblicitaria e retargeting</h2>
                <p>Vengono utilizzati per erogare pubblicità &nbsp;a soggetti che hanno precedentemente visitato questo sito o&nbsp;basata sugli interessi manifestati attraverso la navigazione in internet (OBA).</p>
                <p>Troverai di seguito il nome dei terzi che li gestiscono, e per ciascuno di essi il link alla pagina nella quale potrai ricevere le informazioni sul trattamento e esprimere il tuo consenso.&nbsp;</p>
                <p>
                    <strong>Adform</strong><br />
                    <a href="http://site.adform.com/privacy-policy/">Privacy Policy<span>&nbsp;-&nbsp;</span>Disattiva</a>
                </p>
                <p><strong>Google</strong><br />
                    <a href="https://www.google.com/policies/technologies/ads/">Privacy Policy</a> -&nbsp;<a href="https://www.google.com/settings/u/0/ads">Disattiva</a></p>
                <p><strong>Facebook</strong><br />
                    <a href="https://www.facebook.com/about/privacy/">Privacy Policy</a>&nbsp;- <a href="https://www.facebook.com/settings?tab=ads">Disattiva</a></p>
                <p><strong>Ricordati che puoi gestire le tue preferenze sui cookie anche attraverso il browser </strong></p>
                <p>Se non conosci il tipo e la versione di browser che stai utilizzando, clicca su “Aiuto” nella finestra del browser in alto, da cui puoi accedere a tutte le informazioni necessarie.</p>
                <p>Se invece conosci il tuo browser clicca su quello che stai utilizzando per accedere alla pagina di gestione dei cookie.</p>
                <p>Internet Explorer:&nbsp;<a href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies" target="_blank">http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies</a></p>
                <p>Google Chrome:&nbsp;<a href="https://support.google.com/accounts/answer/61416?hl=it" target="_blank">https://support.google.com/accounts/answer/61416?hl=it</a></p>
                <p>Mozilla Firefox:&nbsp;<a href="http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies" target="_blank">http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies</a></p>
                <p>Safari:&nbsp;<a href="http://www.apple.com/legal/privacy/" target="_blank">http://www.apple.com/legal/privacy/</a></p>
                <p>Per maggiori informazioni, visita la pagina <a href="http://www.youronlinechoices.eu" target="_blank">www.youronlinechoices.eu</a>.</p>
                <p>Lì, 15.05.2015</p>
            </Megacontent.Editorial>


        </Layout>
    )
};

export default AboutUsPage;
